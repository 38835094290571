import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5037811f"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "pm-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SalesCategoriesComponent = _resolveComponent("SalesCategoriesComponent")!
  const _component_SalesCategoryComponent = _resolveComponent("SalesCategoryComponent")!

  return (_ctx.hasAllRequiredData)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_SalesCategoriesComponent, { salesState: _ctx.salesState }, null, 8, ["salesState"]),
        (_ctx.salesState.category)
          ? (_openBlock(), _createBlock(_component_SalesCategoryComponent, {
              key: 0,
              salesState: _ctx.salesState
            }, null, 8, ["salesState"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}