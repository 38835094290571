
import { computed, reactive, ref, defineComponent, watch } from "vue"
import { useRouter, useRoute } from "vue-router"
import SalesCategory from "@/models/SalesCategory"
import InputNumber from "primevue/inputnumber"
import SalesService from "@/services/SalesService"
import SalesCategoriesComponent from "@/components/sales/salesCategoriesComponent.vue"
import SalesCategoryComponent from "@/components/sales/salesCategoryComponent.vue"

export default defineComponent({
  components: { InputNumber, SalesCategoriesComponent, SalesCategoryComponent },
  props: {},
  setup(props) {
    const salesService = new SalesService()
    const salesState = reactive({
      category: null,
      categories: null,
      languages: [],
      regions: [],
      currencies: [],
      addProductDialogVisible: false,
    })

    const hasAllRequiredData = computed(() => {
      return (
        salesState.languages?.length && salesState.regions?.length && salesState.currencies?.length
      )
    })

    Promise.all([
      salesService.getLanguages(),
      salesService.getRegions(),
      salesService.getCurrencies(),
    ]).then((res: any) => {
      //handle languages
      if (salesState.languages?.length) {
        salesState.languages.length = 0
      }
      ;[].push.apply(salesState.languages, res[0])

      //handle regions
      if (salesState.regions?.length) {
        salesState.regions.length = 0
      }
      ;[].push.apply(salesState.regions, res[1])

      //handle currencies
      if (salesState.currencies?.length) {
        salesState.currencies.length = 0
      }
      ;[].push.apply(salesState.currencies, res[2])
    })

    return {
      salesState,
      hasAllRequiredData,
    }
  },
})
