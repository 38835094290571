
import { computed, reactive, ref, defineComponent, watch } from "vue"
import { useRouter, useRoute } from "vue-router"
import SalesCategory from "@/models/SalesCategory"
import InputNumber from "primevue/inputnumber"
import SalesService from "@/services/SalesService"
import CategoryPicker from "../tasks/category/CategoryPicker.vue"
import SalesProductComponent from "./salesProductComponent.vue"
import PrimaryButton from "../buttons/PrimaryButton.vue"
import FilterOverlay from "../data/FilterOverlay.vue"

export default {
  name: "SalesCategoriesComponent",
  components: { InputNumber, SalesProductComponent, PrimaryButton, FilterOverlay },
  props: {
    salesState: {
      type: Object,
    },
  },
  setup(props: any) {
    const salesService = new SalesService()
    const createCategoryDialogVisible = ref(false)
    const newCategoryAlias = ref("")
    const priority = ref(0)
    const language = ref("EN")
    const categories = reactive([])
    const filteredCategories = reactive([])
    const showDeleteCategoryDialog = ref(false)
    const categoryToByDeleted = ref(null)
    const editCategoryDialogVisible = ref(false)
    const editCategoryAlias = ref("")
    const editCategoryPriority = ref(0)
    props.salesState.categories = categories
    const categoryNameFilterValue = ref()
    const categoryNameOptions = ["EQ DR", "EQ US", "SA DR", "SA US", "SA DT"]

    function showEditCategoryDialog(category: SalesCategory) {
      editCategoryAlias.value = category.alias
      editCategoryPriority.value = category.priority
      editCategoryDialogVisible.value = true
    }

    const externalData = props.salesState.languages.map((lang: string) => {
      return { language: lang, description: "", title: "" }
    })

    const languages = reactive(externalData.map((data: any) => data.language))

    function reloadFrontend() {
      props.salesState.category = null
      filteredCategories.forEach((cat) => {
        cat.active = false
      })
      filterCategories()
      initCategoryDetails()
    }

    function getCategories(callback?: any) {
      salesService.getCategories().then((res) => {
        categoryNameFilterValue.value = null
        props.salesState.category = null
        categories.length = 0
        ;[].push.apply(categories, res)
        filterCategories()
        initCategoryDetails()
        callback && callback()
      })
    }

    function filterCategories() {
      filteredCategories.length = 0
      if (categoryNameFilterValue.value?.length) {
        ;[].push.apply(
          filteredCategories,
          categories.filter((category: any) =>
            categoryNameFilterValue.value.includes(category.alias.slice(0, 5).toUpperCase())
          )
        )
      } else {
        ;[].push.apply(filteredCategories, categories)
      }
    }

    function initCategoryDetails() {
      props.salesState.languages.forEach((lang: string) => {
        filteredCategories.forEach((category: SalesCategory) => {
          const hasDetailsForThisLang = category.details.find((detail: any) => {
            return detail.language === lang
          })
          if (!hasDetailsForThisLang) {
            category.details.push({
              title: "",
              description: "",
              language: lang,
            })
          }
        })
      })
    }

    function showCreateCategoryDialog() {
      createCategoryDialogVisible.value = true
    }

    function createSalesCategory() {
      const newSalesCategory = {
        alias: newCategoryAlias.value,
        priority: 1,
      }
      salesService.createCategory(newSalesCategory).then((id: any) => {
        salesService
          .createCategoryDetails(id, {
            title: "",
            description: "",
            language: language.value,
          })
          .then((res: any) => {
            //props.salesState.initCategoryDetails(id)
            createCategoryDialogVisible.value = false
            getCategories()
          })
      })
    }

    function editSalesCategory() {
      const newSalesCategory = {
        alias: editCategoryAlias.value,
        priority: editCategoryPriority.value,
      }
      salesService.editCategory(props.salesState.category.id, newSalesCategory).then((id: any) => {
        editCategoryDialogVisible.value = false
        getCategories()
      })
    }

    function activateCategory(category: SalesCategory) {
      filteredCategories.forEach((cat) => {
        cat.active = false
      })
      category.active = true
      props.salesState.category = category
      props.salesState?.getProductsList && props.salesState.getProductsList()
    }

    function deleteCategory(category: SalesCategory) {
      showDeleteCategoryDialog.value = true
      categoryToByDeleted.value = category
    }

    function cancelDeleteCategory() {
      showDeleteCategoryDialog.value = false
    }

    function confirmDeleteCategory() {
      salesService.deleteCategory(categoryToByDeleted.value.id).then(() => {
        showDeleteCategoryDialog.value = false
        getCategories()
      })
    }

    function clearFilter() {
      categoryNameFilterValue.value = null
      reloadFrontend()
    }

    getCategories()
    props.salesState.getCategories = getCategories

    return {
      createCategoryDialogVisible,
      showCreateCategoryDialog,
      newCategoryAlias,
      createSalesCategory,
      priority,
      language,
      languages,
      externalData,
      activateCategory,
      deleteCategory,
      cancelDeleteCategory,
      confirmDeleteCategory,
      showDeleteCategoryDialog,
      showEditCategoryDialog,
      editCategoryDialogVisible,
      editCategoryAlias,
      editCategoryPriority,
      editSalesCategory,
      categoryNameFilterValue,
      categoryNameOptions,
      reloadFrontend,
      filteredCategories,
      clearFilter,
    }
  },
}
