
import SalesService from "@/services/SalesService"
import { computed, reactive, ref, defineComponent, watch } from "vue"
import { useRouter, useRoute } from "vue-router"
import SalesProductComponent from "@/components/sales/salesProductComponent.vue"
import PrimaryButton from "../buttons/PrimaryButton.vue"

export default defineComponent({
  name: "SalesCategoryComponent",
  components: {
    SalesProductComponent,
    PrimaryButton,
  },
  props: {
    salesState: {
      type: Object,
    },
  },
  setup(props) {
    const salesService = new SalesService()
    const language = ref("EN")
    const products = reactive([])
    const linkProductToCategoryDialogVisible = ref(false)
    const productReloadKey = ref(0)

    const visibleCategoryDetails = computed(() => {
      return props.salesState?.category?.details.find((detail: any) => {
        return detail.language === language.value
      })
    })

    const regions = ref(props.salesState.regions.map((reg: any) => ({ name: reg })))
    const currencies = ref(props.salesState.currencies.map((cur: any) => ({ name: cur })))

    const selectedRegion = ref({ name: props.salesState.regions[0] })
    const selectedCurrency = ref({ name: props.salesState.currencies[0] })

    function getProductsList() {
      salesService.getProductsList(props.salesState.category.id).then((_products) => {
        products.length = 0
        //enrich product details with empty models
        _products.forEach((prod: any) => {
          props.salesState.languages.forEach((lang: any) => {
            const foundDetails = prod.details.find((det: any) => {
              return det.language === lang
            })
            if (!foundDetails) {
              prod.details.push({
                title: "",
                description: "",
                language: lang,
              })
            }
            //get product images here
            prod.images.forEach((image: any) => {
              salesService.getProductImage(image.id).then((imageUrl: any) => {
                image.src = imageUrl
                productReloadKey.value++
              })
            })
          })
        })
        //add products
        ;[].push.apply(products, _products)
      })
    }

    /*watch(props.salesState.category, getProductsList)*/
    getProductsList()
    props.salesState.getProductsList = getProductsList

    function showAddProductDialog() {
      props.salesState.addProductDialogVisible = true
    }

    function updateCategoryData() {
      const foundCatDetails = props.salesState.category.details.find((catDet: any) => {
        return language.value === catDet.language
      })
      if (foundCatDetails?.id) {
        //Exists already in backend so update
        salesService.updateCategoryDetails(
          props.salesState.category.id,
          foundCatDetails.id,
          foundCatDetails
        )
      } else {
        //Doesn't exist yet in backend so create
        salesService.createCategoryDetails(props.salesState.category.id, foundCatDetails)
      }
    }

    return {
      language,
      products,
      visibleCategoryDetails,
      showAddProductDialog,
      updateCategoryData,
      productReloadKey,
      regions,
      selectedRegion,
      currencies,
      selectedCurrency,
    }
  },
})
